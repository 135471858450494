import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/libation-logo.png'
import phone from '../images/phone.png'
import location from '../images/location-pin.png'
import email from '../images/email.svg'
import './footer.css'

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      background: `#f6f6f6`,
    }}
  >
    <div
      className="footer-content-wrap"
      style={{
        display: 'flex',
        position: 'relative'
      }}>
      <div className="logo-wrapper">
        <img className="nav-logo" src={logo} alt="Logo" />
      </div>
      <div className="footer-content flex-stack">
        <div className="flex footer-columns">
          <div className="flex footer-col">
            <div className="footer-icon" style={{backgroundImage: `url(${location})`}}></div>
            <div className="flex-stack">
              <p>215 Fort York, Suite 405<br/>
                Toronto, ON, Canada<br/>
              </p>
            </div>
          </div>
          <div className="flex footer-col">
            <div className="footer-icon" style={{backgroundImage: `url(${phone})`}}></div>
            <div className="flex-stack">
              <p>917-510-4577<br/>
                416-428-9666
              </p>
            </div>
          </div>
          <div className="flex footer-col">
            <div className="footer-icon" style={{backgroundImage: `url(${email})`}}></div>
            <div className="flex-stack footer-email-wrapper">
              <a href="mailto:info@libationltd.com">info@libationltd.com</a>
            </div>
          </div>
        </div>
        © {new Date().getFullYear()} Libation ltd
      </div>
    </div>
  </footer>
)

export default Footer
