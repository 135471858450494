import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/libation-logo.png'
import './header.css'

function isActive(link) {
  if (typeof window !== 'undefined' && window.location.pathname.includes(link) && link !== "/") {
    return "active"
  } else if (typeof window !== 'undefined' && window.location.pathname === link ) {
    return "active"
  } else {
    return ""
  }
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1em`,
    }}
  >
    <div
      style={{
        height: 100,
        display: 'flex',
        position: 'relative'
      }}>
      <div className="logo-wrapper">
        <img className="nav-logo" src={logo} alt="Logo" />
      </div>
      <div className="nav-links">
        <div>
          <a className={"link " + isActive("/")} href="/">Home</a>
        </div>
        <div className="with-dropdown">
          <a className={"link " + isActive("services")} href="/services">Services</a>
          <div className="nav-dropdown">
            <a className="dd-link" href="/services/development-training">Staff & Consumer Training</a>
            <a className="dd-link" href="/services/vip-events-parties">VIP Events & Parties</a>
            <a className="dd-link" href="/services/ambassadorship-representation">Ambassadorship & Representation</a>
            <a className="dd-link" href="/services/workplace-culture-harassment-safety">Workplace Culture, Harassment & Safety</a>
          </div>
        </div>
        <div>
          <a className={"link " + isActive("team")} href="/team/">Team</a>
        </div>
        <div>
          <a className="link">Gallery</a>
        </div>
        <div>
          <a className={"link " + isActive("contact")} href="/contact/">Contact</a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
